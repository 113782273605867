import React from "react";
import { Table, Modal } from "antd";
import { FaCog, FaTrash, FaBan, FaCheckCircle } from "react-icons/fa";
import { IPromoCode } from "../../../data-access";
import "./news-table.scss";
import dayjs from "dayjs";

const { confirm } = Modal;

const baseClassName = "news-table";

interface INewsTableProps {
  data: IPromoCode[];
  isLoading?: boolean;
  total: number;
  currentPage?: number;
  perPage?: number;
  onDelete: (discountId: number) => void;
  onEdit: (discountId: number) => void;
}

const TableFooter = ({ total }: { total: number }): JSX.Element => {
  return (
    <div className="table-footer">
      <strong>Total:</strong> {total}
    </div>
  );
};

export const NewsTable = ({
  data,
  isLoading = false,
  total,
  currentPage = 1,
  perPage = 10,
  onDelete,
  onEdit,
}: INewsTableProps): JSX.Element => {
  const showConfirm = (discount: any): void => {
    confirm({
      title: `Are you sure you want to delete "${discount.title}"?`,
      okText: "Delete",
      async onOk() {
        onDelete(discount.id);
        return new Promise((resolve) => resolve(true));
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (title: string) => <span>{title}</span>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description: string) => <span>{description}</span>,
    },
    {
      title: "image",
      dataIndex: "image",
      key: "image",
      render: (image: string) => {
        return image ? <FaCheckCircle /> : <FaBan />;
      },
    },
    {
      title: "link",
      key: "link",
      dataIndex: "link",
      render: (link: string) => {
        return link ? <FaCheckCircle /> : <FaBan />;
      },
    },
    {
      title: "linkTitle",
      key: "linkTitle",
      dataIndex: "linkTitle",
      render: (linkTitle: string) => {
        return linkTitle ? <span>{linkTitle}</span> : <FaBan />;
      },
    },
    {
      title: "date",
      key: "date",
      dataIndex: "date",
      render: (date: string) => <span>{dayjs(date).format('YYYY-MM-DD')}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (value: any, record: any, index: number) => (
        <div className="control-buttons">
          <button onClick={() => onEdit(data[index].id)}>
            <FaCog />
          </button>
          <button onClick={() => showConfirm(data[index])}>
            <FaTrash />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className={baseClassName}>
      <Table
        columns={columns}
        dataSource={data}
        footer={() => <TableFooter total={data.length} />}
        loading={isLoading}
        pagination={{
          total,
          current: currentPage,
          pageSize: perPage,
        }}
      />
    </div>
  );
};
