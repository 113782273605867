import { Dayjs } from "dayjs";

export enum VALIDATION_MESSAGES {
  INVALID_EMAIL = "Oops! Invalid email address.",
}

export interface IExtensionLog {
  id: number;
  action: string;
  method: string;
  email: string;
  data: { [key: string]: string };
  source: string;
  created_at: string;
  updated_at: string;
}

export interface IAuthState {
  auth_token: string;
  user: {
    name: string;
    email: string;
  };
}

export interface IExtensionLogsResponse {
  data: IExtensionLog[];
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    links: {
      url: string | null;
      label: string | null;
      active: boolean;
    }[];
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

export interface IBackendLog {
  id: number;
  user_id: string | null;
  method: string;
  path: string;
  all: {
    id: string | null;
    method: string | null;
    email: string | null;
    user_id: string | null;
    path: string | null;
    response_code: string | null;
  };
  headers: {
    connection: string;
    "accept-encoding": string;
    host: string;
    "postman-token": string;
    "cache-control": string;
    accept: string;
    "user-agent": string;
    "content-length": string;
    "content-type": string;
  };
  ip: string;
  created_at: string;
  updated_at: string;
  response: {
    code: number;
    response: string;
  } | null;
  response_code: string | null;
}

export interface IBackendLogsResponse {
  data: IBackendLog[];
  links: {
    first: string | null;
    last: string | null;
    prev: string | null;
    next: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    links: [
      {
        url: string | null;
        label: string;
        active: boolean;
      }
    ];
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

export interface IBackendLogsFilter {
  id?: string;
  method?: string;
  email?: string;
  path?: string;
  response_code?: string;
  create_at?: string;
  page?: number;
  per_page?: number;
  order_by?: string;
}

export interface IExtensionLogsFilter {
  email?: string;
  action?: string;
  method?: string;
  created_at?: string;
  page?: number;
  per_page?: number;
  order_by?: string;
}

export type LogAction =
  | "REQUEST_SUCCESS"
  | "REQUEST_FAILED"
  | "EXTENSION_ERROR"
  | "EXTENSION_INSTALLED"
  | "EXTENSION_UPDATED";

export enum ActionColor {
  REQUEST_SUCCESS = "green",
  REQUEST_FAILED = "red",
  EXTENSION_INSTALLED = "blue",
  EXTENSION_UPDATED = "gold",
}

export enum MethodColor {
  GET = "green",
  POST = "lime",
  DELETE = "red",
  PATCH = "orange",
}

export interface ILoginParams {
  email: string;
  password: string;
}

export interface ILoginResponse {
  data: {
    auth_token: string;
    user: {
      name: string;
      email: string;
    };
  };
}

export interface IPromoCode {
  id: number;
  name: string;
  discount: number;
  fromDate: string | null;
  toDate: string | null;
  created_at: string | null;
  updated_at: string | null;
  isStarter: boolean;
  isYearly: boolean;
}

export interface ICreateDiscountFormData {
  id?: number;
  name: string;
  discount: number | null;
  startDate: Dayjs | string;
  endDate: Dayjs | string;
  plans: { name: string; checked: boolean }[];
}

export interface ICreateNewFormData {
  id?: number;
  title: string;
  description: string;
  image: string;
  link: string;
  link_title: string;
  date: string;
  isActive: boolean;
}

export type LicencePlan = "Trial" | "Lite" | "Starter" | "Yearly";

export interface ISubscriptionTeam {
  email: string;
  role: "admin" | "owner" | "member";
  licensePlan: LicencePlan;
}

export interface ISubscription {
  autoUpdate: boolean | null;
  email: string;
  endDate: string;
  isCancelled: boolean;
  startDate: string;
  subscribedDays: number;
  team: ISubscriptionTeam[];
  teamSize?: number;
}

export enum ChartMetrics {
  "trial",
  "lite",
  "starter",
  "yearly",
  "ai_requests",
}

export interface IFetchSubscriptionsGraphsResponse {
  data: {
    payments: {
      graph: {
        datasets: { payments: number[] };
        labels: string[];
      };
    };
    subscriptions: {
      graph: {
        datasets: {
          lite: number[];
          starter: number[];
          trial: number[];
          yearly: number[];
          ai_requests: number[];
        };
        labels: string[];
      };
    };
  };
}

export interface ISubscriptionsBlock {
  name: string;
  type: string;
  subscriptions: number;
}

export interface IFetchSubscriptionsBlocks {
  data: ISubscriptionsBlock[];
}

export enum ColumnAlign {
  CENTER = "center",
  LEFT = "left",
  RIGHT = "right",
  JUSTIFY = "justify",
  CHAR = "char",
}

export enum UsersCard {
  totalUsers = "Total",
  paidUsers = "Paid",
  trialUsers = "Trial",
  activeUsers = "Active",
  pipedriveUsers = "Pipedrive",
}

export enum SubscriptionPlanColor {
  totalUsers = "#F7FA71",
  paidUsers = "#84E8E2",
  trialUsers = "#C2EC8B",
  activeUsers = "#FCB766",
  pipedriveUsers = "#9284E8",
}
