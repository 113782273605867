import { NavLink } from "react-router-dom";
import { FaCogs, FaPercentage, FaRegNewspaper, FaUsers } from "react-icons/fa";
import { APP_ROUTES } from "../../data-access";
import { BrandLogo } from "../../ui";
import "./sidebar.scss";

const baseClass = "sidebar";

export const Sidebar = (): JSX.Element => (
  <nav className={baseClass}>
    <BrandLogo />
    <NavLink to={APP_ROUTES.extensionLogs}>
      <FaCogs className={`${baseClass}__icon`} />
      Extension Logs
    </NavLink>
    <NavLink to={APP_ROUTES.backendLogs}>
      <FaCogs className={`${baseClass}__icon`} />
      Backend Logs
    </NavLink>
    <NavLink to={APP_ROUTES.discounts}>
      <FaPercentage className={`${baseClass}__icon`} />
      Discounts
    </NavLink>
    <NavLink to={APP_ROUTES.users}>
      <FaUsers className={`${baseClass}__icon`} />
      Users
    </NavLink>
    <NavLink to={APP_ROUTES.news}>
      <FaRegNewspaper className={`${baseClass}__icon`} />
      News
    </NavLink>
  </nav>
);
