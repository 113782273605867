import React from "react";
import { Controller, useForm } from "react-hook-form";
import { ICreateNewFormData } from "../../../data-access";
import { FormButton, FormInput } from "../../../ui";

import "./create-new-form.scss";
import { Switch } from "antd";

const baseClassName = "create-new-form";

interface ICreateDiscountFormProps {
  data?: any;
  onSubmit: (data: ICreateNewFormData) => void;
  onCancel: () => void;
}

export const CreateNewForm = ({
  data,
  onCancel,
  onSubmit,
}: ICreateDiscountFormProps): JSX.Element => {
  const form = useForm<ICreateNewFormData>({
    mode: "all",
    defaultValues: {
      id: data?.id || null,
      title: data?.title || "",
      description: data?.description || "",
      image: data?.image || "",
      link: data?.link || "",
      link_title: data?.link_title || "",
      date: data?.date || "",
      isActive: data?.isActive || true,
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = form;

  return (
    <div className={baseClassName}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="title"
          render={({ field: { name, onBlur, value, ref, onChange } }) => (
            <FormInput
              ref={ref}
              label="Title"
              placeholder="Enter title"
              error={errors?.title?.message}
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { name, onBlur, value, ref, onChange } }) => (
            <FormInput
              ref={ref}
              label="Description"
              placeholder="Enter description"
              error={errors?.description?.message}
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="image"
          render={({ field: { name, onBlur, value, ref, onChange } }) => (
            <FormInput
              ref={ref}
              label="Image link"
              placeholder="Enter link"
              error={errors?.image?.message}
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="link"
          render={({ field: { name, onBlur, value, ref, onChange } }) => (
            <FormInput
              ref={ref}
              label="Link URL"
              placeholder="Enter link"
              error={errors?.link?.message}
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="link_title"
          render={({ field: { name, onBlur, value, ref, onChange } }) => (
            <FormInput
              ref={ref}
              label="Link Title"
              placeholder="Enter link title"
              error={errors?.link_title?.message}
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="date"
          render={({ field: { name, onBlur, value, ref, onChange } }) => (
            <FormInput
              ref={ref}
              label="Date"
              placeholder="Enter news date"
              error={errors?.date?.message}
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="isActive"
          render={({ field: { value, ref, onChange } }) => (
            <>
              <span style={{ marginRight: "10px", fontWeight: 700 }}>
                Is Active
              </span>
              <Switch ref={ref} checked={value} onChange={onChange} />
            </>
          )}
        />
        <div className="form-controls">
          <FormButton theme="blue" onClick={onCancel}>
            Cancel
          </FormButton>
          <FormButton disabled={!isDirty || !isValid} htmlType="submit">
            Submit
          </FormButton>
        </div>
      </form>
    </div>
  );
};
