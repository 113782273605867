import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { newsApi } from "../../api/news";
import {
  IAuthState,
  ICreateNewFormData,
  IPromoCode,
} from "../../data-access";
import { notify } from "../../utils";
import { NewsTable } from "./news-table";
import { CreateNewForm } from "./create-new-form";
import { FormButton } from "../../ui";
import "./news.scss";

const baseClassName = "news";

export const News = (): JSX.Element => {
  const authUser = useSelector((state: { auth: IAuthState }) => state.auth);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [newForEdit, setNewForEdit] = useState<any>(
    {} as any
  );
  const [newsList, setNewsList] = useState<any[]>([]);

  const toggleCreateModal = (): void => {
    setIsCreateModalOpen(!isCreateModalOpen);
  };

  const toggleUpdateModal = (): void => {
    setIsUpdateModalOpen(!isUpdateModalOpen);
  };

  const handleCreateNews = (newItem: ICreateNewFormData): void => {
    setIsLoading(true);
    newsApi
      .createNews(authUser.auth_token, newItem)
      .then((response: { data: any }) => {
        setIsCreateModalOpen(false);
        notify("success", `New has been successfully created!`);
      })
      .catch((error: { message: string; status?: number }) => {
        notify("error", error.message);
      })
      .finally(() => setIsLoading(false));
  };

  const handleResetNews = (): void => {
    setIsLoading(true);
    newsApi
      .activateNewsForAll(authUser.auth_token)
      .then(() => {
        notify("success", `News has been successfully activated for all!`);
      })
      .catch((error: { message: string; status?: number }) => {
        notify("error", error.message);
      })
      .finally(() => setIsLoading(false));
  };

  const handleNewDelete = (id: number): void => {
    setIsLoading(true);
    newsApi
      .deleteNewsItem(authUser.auth_token, id)
      .then(() => {
        const updatedList = newsList.filter(n => n.id !== id);
        setNewsList(updatedList);
        notify("success", `News item has been successfully deleted!`);
      })
      .catch((error: { message: string; status?: number }) => {
        notify("error", error.message);
      })
      .finally(() => setIsLoading(false));
  };

  const handleNewEdit = (promoCodeId: number): void => {
    const targetNew = newsList.find(
      (n: any) => n.id === promoCodeId
    );

    if (targetNew) {
      setNewForEdit(targetNew);
      setIsUpdateModalOpen(true);
    }
  };

  const handleUpdateNews = (updateItem: any): void => {
    setIsLoading(true);
    newsApi
      .updateNewsItem(authUser.auth_token, updateItem.id, updateItem)
      .then((response: { data: any }) => {
        const updatedList = newsList.map(n => {
          if (n.id === updateItem.id) {
            n = response.data;
          }
          return n;
        });
        setNewsList(updatedList);

        setIsUpdateModalOpen(false);
        notify("success", `New has been successfully updated!`);
      })
      .catch((error: { message: string; status?: number }) => {
        notify("error", error.message);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    newsApi
      .getNews(authUser.auth_token)
      .then((response: { data: IPromoCode[] }) => {
        setNewsList(response.data);
      })
      .catch((error: { message: string; status?: number }) => {
        notify("error", error.message);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className={baseClassName}>
      <h3 className={`${baseClassName}__title`}>
        News
        <div className={`${baseClassName}__header-actions`}>
          <FormButton htmlType="button" onClick={toggleCreateModal}>
            Add +
          </FormButton>
          <FormButton htmlType="button" theme="blue" onClick={handleResetNews}>
            Reset News Visible
          </FormButton>
        </div>
      </h3>
      <NewsTable
        data={newsList}
        total={newsList.length}
        currentPage={1}
        isLoading={isLoading}
        perPage={10}
        onDelete={handleNewDelete}
        onEdit={handleNewEdit}
      />
      <Modal
        title={"Create News Item"}
        open={isCreateModalOpen}
        footer={null}
        destroyOnClose={true}
        closeIcon={null}
        confirmLoading={isLoading}
      >
        <CreateNewForm
          onCancel={toggleCreateModal}
          onSubmit={handleCreateNews}
        />
      </Modal>
      <Modal
        title={"Update News Item"}
        open={isUpdateModalOpen}
        footer={null}
        destroyOnClose={true}
        closeIcon={null}
        confirmLoading={isLoading}
      >
        <CreateNewForm
          data={newForEdit}
          onCancel={toggleUpdateModal}
          onSubmit={handleUpdateNews}
        />
      </Modal>
    </div>
  );
};
