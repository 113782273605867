import { baseUrl, handleResponse } from "./base";

const requestOptions = {
  method: "GET",
  mode: "cors",
  cache: "no-cache",
  credentials: "same-origin",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
} as any;

const getNews = (token: string): Promise<any> => {
  return fetch(`${baseUrl}api/news`, {
    ...requestOptions,
    headers: {
      ...requestOptions.headers,
      Authorization: `Bearer ${token}`,
    },
  }).then(handleResponse);
};

const createNews = (token: string, data: any): Promise<any> => {
  delete data.id;
  return fetch(`${baseUrl}api/news`, {
    ...requestOptions,
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      ...requestOptions.headers,
      Authorization: `Bearer ${token}`,
    },
  }).then(handleResponse);
};

const activateNewsForAll = (token: string): Promise<any> => {
  return fetch(`${baseUrl}api/news/activate-for-all`, {
    ...requestOptions,
    method: "POST",
    body: JSON.stringify(null),
    headers: {
      ...requestOptions.headers,
      Authorization: `Bearer ${token}`,
    },
  }).then(handleResponse);
};

const deleteNewsItem = (token: string, id: number): Promise<any> => {
  return fetch(`${baseUrl}api/news/${id}`, {
    ...requestOptions,
    method: 'DELETE',
    headers: {
      ...requestOptions.headers ,
      'Authorization': `Bearer ${token}`
    }
  }).then(handleResponse);
}

const updateNewsItem = (token: string, id: number, data: any): Promise<{ data: any }> => {
  delete data.id;
  return fetch(`${baseUrl}api/news/${id}`, {
    ...requestOptions,
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      ...requestOptions.headers ,
      'Authorization': `Bearer ${token}`
    }
  }).then(handleResponse);
}

export const newsApi = {
  getNews,
  createNews,
  activateNewsForAll,
  deleteNewsItem,
  updateNewsItem,
};
